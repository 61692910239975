/* eslint-disable no-use-before-define, no-restricted-globals */

import * as ibantools from "ibantools";
import Cookies from "js-cookie";
import EmailValidator from "email-validator";
import {
  debounce,
  forEach,
  htmlToElement,
  parseJSON,
  selfOrClosest,
} from "./util";
import {
  addToCart as gtmAddToCart,
  checkout as gtmCheckout,
  checkoutOptions as gtmCheckoutOptions,
} from "./donate-gtm";
import { pushNewsletterSubscription } from "./gtm-event";

const STEP_ATTRIBUTE = "data-step";
const TO_STEP_ATTRIBUTE = "data-to-step";
const VISIBLE_ATTRIBUTE = "data-visible-when";
const ONLY_ONE_ATTRIBUTE = "data-only-one-of-children-allowed";
const VALIDATION_ATTRIBUTE = "data-validate";

const CURRENCY_NAME = "currency";
const FREQUENCY_NAME = "frequency";
const AMOUNT_NAME = "amount";
const AMOUNT_CUSTOM_NAME = "amount_custom";
const PAYMENT_NAME = "paymentmethod";
const COUNTRY_NAME = "country";
const ON_BEHALF_OF_COMPANY_NAME = "on_behalf_of";
const IN_THE_NAME_OF_NAME = "in_the_name_of";
const MAX_AMOUNT = 50000;

const NAME_MAPPER = {
  [CURRENCY_NAME]: CURRENCY_NAME,
  [FREQUENCY_NAME]: FREQUENCY_NAME,
  [AMOUNT_NAME]: AMOUNT_NAME,
  [AMOUNT_CUSTOM_NAME]: AMOUNT_CUSTOM_NAME,
  [PAYMENT_NAME]: PAYMENT_NAME,
  [COUNTRY_NAME]: COUNTRY_NAME,
  [ON_BEHALF_OF_COMPANY_NAME]: ON_BEHALF_OF_COMPANY_NAME,
  [IN_THE_NAME_OF_NAME]: IN_THE_NAME_OF_NAME,
};

const DonateForm = (container) => {
  const state = {
    step: 1,
    submitting: false,
    on_behalf_of: false,
    in_the_name_of: false,
  };

  const form = container.querySelector("form");
  const stepsElement = document.querySelector(".js-steps");
  const totalStepsElement = document.querySelector(".js-total-step");
  const currentStepElement = document.querySelector(".js-current-step");
  const secondStepSubmitButton = document.querySelector(
    ".second-step-submit-button",
  );
  const monthlyPreselectAmount = document.querySelector(
    '[data-currency-preselect="monthly"]',
  );
  const oneTimePreselectAmount = document.querySelector(
    '[data-currency-preselect="one-time"]',
  );
  const paymentSectionElement = container.querySelector(
    ".js-donate-form__payment",
  );

  const setState = (key, value) => {
    state[key] = value;
  };

  const getState = (key) => (key ? state[key] : state);

  const isTag = (input, name) =>
    input.tagName && input.tagName.toLowerCase() === name;

  const isType = (input, type) => input.getAttribute("type") === type;

  const isInteger = (value) => {
    return (
      typeof value === "number" &&
      isFinite(value) &&
      Math.floor(value) === value
    );
  };

  const isRegularInput = (input) => {
    const type = input.getAttribute("type");
    return (
      type !== "checkbox" &&
      type !== "radio" &&
      type !== "button" &&
      type !== "submit" &&
      type !== "hidden"
    );
  };

  const getIterableInputOrChildInputs = (el) => {
    return isTag(el, "input") || isTag(el, "select")
      ? [el]
      : [...el.querySelectorAll("input, select")];
  };

  /**
   * Whether or not the element should be toggled based on the state.
   */
  const shouldBeToggled = (conditions) => {
    return Object.keys(conditions).every((key) => getState(key) !== undefined);
  };

  /**
   * Whether or not given conditions are met based on the state.
   */
  const meetsConditions = (conditions) => {
    return Object.keys(conditions).every(
      (key) => getState(key) === conditions[key],
    );
  };

  const setCheckedOnAmountFieldForFrequency = () => {
    const frequency = getState(FREQUENCY_NAME);
    const amount = getState(AMOUNT_NAME);
    const amountInputs = container.querySelectorAll(`[name="${AMOUNT_NAME}"]`);

    const getAmountInputForFrequency = () => {
      return [...amountInputs].find((input) => {
        const dataFrequency = input.getAttribute("data-frequency");
        return dataFrequency === frequency && input.value === amount;
      });
    };

    const amountInputToCheck = getAmountInputForFrequency();
    if (!amountInputToCheck) {
      return;
    }
    amountInputToCheck.checked = true;
  };

  const validateAmount = (value) => {
    const integerCheck = {
      isValid: isInteger(parseInt(value, 10)) && parseInt(value, 10) > 0,
      message: "Only rounded numeric values are accepted.",
    };
    if (!integerCheck.isValid) {
      return integerCheck;
    }
    const amountCheck = {
      isValid: integerCheck.isValid && parseInt(value, 10) <= MAX_AMOUNT,
      message:
        "You have entered a very generous amount, which we cannot support through the website. If you would like to make this donation, <a target='_blank' href='/faq/can-i-transfer-an-amount-directly-to-your-bank-account/'>please read how to make a manual bank/wire transfer here</a>. Thank you!",
    };
    return amountCheck;
  };

  const validateIBAN = (value) => ({
    isValid: ibantools.isValidIBAN(value.replace(/\s/g, "")),
    message: "Provide a valid IBAN.",
  });

  const validateBic = (value) => ({
    isValid: ibantools.isValidBIC(value.replace(/\s/g, "")),
    message: "Provide a valid BIC.",
  });

  const validateEmail = (value) => ({
    isValid: EmailValidator.validate(value),
    message:
      "Please provide a valid email address, which will be used for follow-up on your payments (receipts, error messages, etc).",
  });

  const validateToggledRegularInput = (value) => ({
    isValid: value !== "",
    message: "Please fill out this field or toggle it off.",
  });

  const validateRadioGroup = (group) => ({
    isValid: group.some((input) => input.checked),
    message: "Choosing one of these options is required.",
  });

  const validateRegularInput = (value) => ({
    isValid: value !== "",
    message: "This field is required.",
  });

  const validateInput = (input) => {
    if (isType(input, "radio")) {
      const groupInputs = form.querySelectorAll(
        `[required][name="${input.getAttribute("name")}"]`,
      );
      return validateRadioGroup([...groupInputs]);
    }
    switch (input.getAttribute(VALIDATION_ATTRIBUTE)) {
      case "amount":
        return validateAmount(input.value);
      case "iban":
        return validateIBAN(input.value);
      case "bic":
        return validateBic(input.value);
      case "email":
        return validateEmail(input.value);
      case "on_behalf_of":
        return validateToggledRegularInput(input.value);
      case "in_the_name_of":
        return validateToggledRegularInput(input.value);
      default:
        return validateRegularInput(input.value);
    }
  };

  const isValidStep = () => {
    const inputs = getStepElement().querySelectorAll(
      "input[required], select[required]",
    );
    return [...inputs].every((input) => validateInput(input).isValid);
  };

  const addError = ({ input, errorLocation, message }) => {
    input.setAttribute("aria-invalid", "true");
    input.setAttribute("aria-describedby", `${input.getAttribute("id")}-error`);
    if (errorLocation.classList.contains("js-error")) {
      return;
    }
    const error = htmlToElement(`
      <ul class="error js-error" role="alert" id="${input.getAttribute(
        "id",
      )}-error">
        <li>${message}</li>
      </ul>
    `);
    errorLocation.parentNode.insertBefore(error, errorLocation);
  };

  const removeError = ({ input, errorLocation }) => {
    input.removeAttribute("aria-invalid");
    input.removeAttribute("aria-describedby");
    if (!errorLocation || !errorLocation.classList.contains("js-error")) {
      return;
    }
    // Only remove errors from the input itself, leave others as be
    if (
      input.getAttribute("id") !==
      errorLocation.getAttribute("id").replace("-error", "")
    ) {
      return;
    }
    errorLocation.parentNode.removeChild(errorLocation);
  };

  const toggleInputError = (input, removeOnly) => {
    const fieldset = selfOrClosest(input, `[aria-labelledby]`);
    const legend = fieldset
      ? form.querySelector(`#${fieldset.getAttribute("aria-labelledby")}`)
      : undefined;
    const label = form.querySelector(`[for="${input.getAttribute("id")}"]`);
    const useLegendToFindErrorLocation =
      (fieldset && !label) || (fieldset && input.nextElementSibling === label);
    const errorLocation = useLegendToFindErrorLocation
      ? legend.nextElementSibling
      : label.nextElementSibling;
    const validatedInput = validateInput(input);
    if (validatedInput.isValid || !input.hasAttribute("required")) {
      removeError({ input, errorLocation });
    } else if (!removeOnly) {
      addError({ input, errorLocation, message: validatedInput.message });
    }
  };

  const getStepElement = () =>
    form.querySelector(`[data-step="${getState("step")}"]`);

  const toggleErrors = (removeOnly = false) => {
    const requiredInputs = getStepElement().querySelectorAll("input, select");
    forEach([...requiredInputs], (input) =>
      toggleInputError(input, removeOnly),
    );
  };

  const setStep = (index) => {
    const safeIndex = parseInt(index, 10);
    if (safeIndex > getState("step") && !isValidStep()) {
      toggleErrors();
      return;
    }
    setState("step", safeIndex);
    forEach(form.querySelectorAll(`[${STEP_ATTRIBUTE}]`), (step) => {
      if (parseInt(step.getAttribute(STEP_ATTRIBUTE), 10) === safeIndex) {
        step.removeAttribute("aria-hidden");
        step.querySelector("input").focus();
        return;
      }
      step.setAttribute("aria-hidden", "true");
    });
  };

  const uncheckElementOrChildren = (el) => {
    forEach(getIterableInputOrChildInputs(el), (input) => {
      if (isType(input, "radio")) {
        input.checked = false;
      } else if (isTag(input, "select")) {
        input.selectedIndex = 0;
      } else if (!isType(input, "hidden") && !isType(input, "checkbox")) {
        input.value = "";
      }
    });
  };

  const unmarkElementOrChildrenRequired = (el) => {
    forEach(getIterableInputOrChildInputs(el), (input) =>
      input.removeAttribute("required"),
    );
  };

  const markElementOrChildrenRequired = (el) => {
    forEach(getIterableInputOrChildInputs(el), (input) =>
      input.setAttribute("required", ""),
    );
  };

  /**
   * Toggle an element's visibility based on its conditions and the current state.
   */
  const toggleElementVisibility = (el) => {
    const conditions = parseJSON(el.getAttribute(VISIBLE_ATTRIBUTE));
    if (!shouldBeToggled(conditions)) {
      return;
    }
    const shouldHide = !meetsConditions(conditions);
    el.setAttribute("aria-hidden", shouldHide);
    if (shouldHide) {
      uncheckElementOrChildren(el);
      unmarkElementOrChildrenRequired(el);
    } else {
      markElementOrChildrenRequired(el);
    }
  };

  /**
   * Inject the frequency + donation amount in the text at the top of the second step.
   */
  const replaceAmountPlaceholder = () => {
    const textElement = document.querySelector(
      ".js-donate-form__donation-remark-text",
    );
    // In the CMS the text "{AMOUNT}" is used as a placeholder for the amount.
    const replaceTarget = "{AMOUNT}";
    const currentAmount = state.amount || state.amount_custom;
    const currentValuta = state.currency === "USD" ? "$" : "€";
    const frequency = state.frequency === "one" ? "one-time" : "monthly";
    const placeholderContent = textElement.getAttribute(
      "data-placeholder-content",
    );
    const contentWithCurrentAmount = placeholderContent.replace(
      replaceTarget,
      `${frequency} ${currentValuta}${currentAmount}`,
    );

    // Replace the "{AMOUNT}" placeholder in the text, with the current amount
    textElement.innerHTML = contentWithCurrentAmount;
  };

  const handleScrollToOnSelectPaymentMethod = (paymentMethod) => {
    // Scroll to the bank selection when the payment method is iDeal or SEPA Direct Debit
    const scrollToBankSelectionArray = ["ideal", "sepadirectdebit"];

    if (scrollToBankSelectionArray.includes(paymentMethod)) {
      return scrollToBankSelection(paymentMethod);
    }

    // Scroll to the second step submit button with the other payment methods.
    return scrollToSecondStepSubmitButton();
  };

  /**
   * Scroll to the bank selection based on the payment method (Ideal or SEPA Direct Debit).
   */
  const scrollToBankSelection = (paymentMethod) => {
    const bankSelection = document.querySelector(
      `.js-donate-form__bank-${paymentMethod}`,
    );
    bankSelection.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  /**
   * Scroll to the second step submit button
   */
  const scrollToSecondStepSubmitButton = () => {
    secondStepSubmitButton.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  /**
   * Toggle children of a parent group, where only one value/selection is allowed
   */
  const toggleExclusiveChildren = (originalInput) => (el) => {
    const inputs = el.querySelectorAll("input");
    const valueMatches = [...inputs].filter((input) => {
      if (input.type === "radio" && !input.checked) {
        return false;
      }
      return input.value === state[NAME_MAPPER[input.name]];
    });
    const originalMatch = valueMatches.filter(
      (input) => input === originalInput,
    );
    const matches = originalMatch.length ? originalMatch : valueMatches;
    forEach(inputs, (input) => {
      if (
        !matches.length ||
        matches[0].getAttribute("aria-hidden") === "true" ||
        matches[0] === input
      ) {
        markElementOrChildrenRequired(input);
      } else {
        uncheckElementOrChildren(input);
        unmarkElementOrChildrenRequired(input);
      }
    });
  };

  const updateState = (el) => {
    const name = el.getAttribute("name");
    if (el.getAttribute("type") === "checkbox") {
      setState([NAME_MAPPER[name]], el.checked);
      return;
    }
    const value = isTag("select")
      ? el.options[el.selectedIndex].value
      : el.value;
    if (!value || !NAME_MAPPER[name]) {
      console.warn(`No value or name found for ${name}`);
      return;
    }
    setState([NAME_MAPPER[name]], value);
    // Reset `amount` or `amount_custom` when the other is filled
    if (name === AMOUNT_NAME || name === AMOUNT_CUSTOM_NAME) {
      const otherName = name === AMOUNT_NAME ? AMOUNT_CUSTOM_NAME : AMOUNT_NAME;
      setState([NAME_MAPPER[otherName]], undefined);
    }
    // Preselect currency, only when the frequency is updated.
    if (el.name === "frequency") {
      preselectCurrency();
    }
  };

  const updateInterface = (originalInput) => {
    forEach(
      container.querySelectorAll(`[${VISIBLE_ATTRIBUTE}]`),
      toggleElementVisibility,
    );
    forEach(
      container.querySelectorAll(`[${ONLY_ONE_ATTRIBUTE}]`),
      toggleExclusiveChildren(originalInput),
    );
    toggleErrors(true);

    // Small hack for custom amount to show it is the current choice when filled.
    const customAmountEl = container.querySelector(
      `[name="${AMOUNT_CUSTOM_NAME}"]`,
    );
    if (state.amount_custom && customAmountEl.required) {
      customAmountEl.classList.add("is-filled");
    } else {
      customAmountEl.classList.remove("is-filled");
    }

    if (
      originalInput &&
      "name" in originalInput &&
      originalInput.name === PAYMENT_NAME &&
      window.innerWidth < 560
    ) {
      handleScrollToOnSelectPaymentMethod(originalInput.getAttribute("value"));
    }

    // Add attribute to adjust the payment layout based on the currency.
    paymentSectionElement.setAttribute("data-currency", state.currency);

    // Update steps donut-chart and text.
    const totalSteps = 3;
    stepsElement.setAttribute("data-total-steps", totalSteps);
    stepsElement.setAttribute("data-current-step", state.step);
    totalStepsElement.innerHTML = totalSteps;
    currentStepElement.innerHTML = state.step;

    if (state.step === 2) {
      replaceAmountPlaceholder();
    }

    setCheckedOnAmountFieldForFrequency();

    secondStepSubmitButton.innerHTML =
      state.frequency === "one" ? "Donate once" : "Donate monthly";
  };

  /**
   * Toggle section that contains extra information about a payment method.
   */
  const togglePaymentMethodExtraInfoDescription = (el) => {
    const isExpanded = el.getAttribute("data-is-expanded") === "true";
    const toggleButton = el.querySelector("button");

    el.setAttribute("data-is-expanded", !isExpanded);
    toggleButton.setAttribute("aria-expanded", !isExpanded);
  };

  /**
   * Preselect the currency based on the frequency.
   */
  const preselectCurrency = () => {
    if (state.frequency === "one") {
      oneTimePreselectAmount.checked = true;
      setState([NAME_MAPPER.amount], oneTimePreselectAmount.value);
    } else {
      monthlyPreselectAmount.checked = true;
      setState([NAME_MAPPER.amount], monthlyPreselectAmount.value);
    }
  };

  const handleInputEvent = (e) => {
    updateState(e.target);
    updateInterface(e.target);
  };

  const handleButtonEvent = (e) => {
    const button = e.target;
    const step = button.getAttribute(TO_STEP_ATTRIBUTE);
    if (isType(button, "submit") || !step) {
      return;
    }
    setStep(step);
    updateInterface();
  };

  const handleLabelEvent = (e) => {
    const label = selfOrClosest(e.target, "label");
    window.setTimeout(
      () => form.querySelector(`#${label.getAttribute("for")}`).blur(),
      0,
    );
  };

  const handleClickEvent = (e) => {
    if (isTag(e.target, "button")) {
      handleButtonEvent(e);
    } else if (selfOrClosest(e.target, "label")) {
      handleLabelEvent(e);
    }
  };

  const handleKeyUpEvent = (e) => {
    if (!isRegularInput(e.target)) {
      return;
    }
    debounce(handleInputEvent, 200)(e);
  };

  const submit = () => {
    form.removeEventListener("submit", handleSubmitEvent);
    form.submit();
  };

  const handleSubmitEvent = (e) => {
    e.preventDefault();
    if (!isValidStep()) {
      toggleErrors();
    } else if (state.step === 1) {
      setStep(2);
      updateInterface();
      gtmAddToCart(state);
      gtmCheckout(state);
    } else {
      // Send email to GTM
      const formData = new FormData(form);
      const email = formData.get("email");
      if (email) {
        pushNewsletterSubscription(email);
      }

      gtmCheckoutOptions(state).then(submit);
      setState("submitting", true);
      updateInterface();
    }
  };

  /**
   * Update elements with a initially default value.
   * Check initially checked input (and notify state and interface about it).
   */
  const setInitialDefaults = (input) => {
    if (input.getAttribute("data-initially-checked")) {
      input.checked = true;
      updateState(input);
      updateInterface(input);
    }

    // Check currency and update state/UI.
    if (input.getAttribute("data-currency-preselect")) {
      oneTimePreselectAmount.checked = true;
      updateState(input);
      updateInterface(input);
    }
  };

  return {
    init() {
      // Add novalidate attribute since we're doing it ourselfs
      form.setAttribute("novalidate", "");

      // Change events, such as radio buttons and regular inputs
      form.addEventListener("change", handleInputEvent);

      // Key events, but only for regular inputs
      form.addEventListener("keyup", handleKeyUpEvent);

      // Click event for blurring styled radio buttons
      form.addEventListener("click", handleClickEvent);

      // Submit event
      form.addEventListener("submit", handleSubmitEvent);

      const customAmountEl = container.querySelector(
        `[name="${AMOUNT_CUSTOM_NAME}"]`,
      );
      const paymentMethodExtraInfoElements = container.querySelectorAll(
        ".js-donate-form__payment-method-extra-info",
      );

      customAmountEl.addEventListener("input", (e) => {
        // Remove anything form the value that is not a number.
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
      });
      paymentMethodExtraInfoElements.forEach((el) => {
        const toggleButton = el.querySelector("button");
        toggleButton.addEventListener("click", () => {
          togglePaymentMethodExtraInfoDescription(el);
        });
      });

      // Reset all inputs (eg. when going `history.back()`)
      const inputs = [...form.querySelectorAll("input, select")].filter(
        (input) => {
          // Do not reset country or currency,
          // since it's prefilled via the microservice.
          return input.name !== "country" && input.name !== "currency";
        },
      );
      inputs.forEach(uncheckElementOrChildren);
      inputs.forEach(setInitialDefaults);
    },

    addGoogleAnalyticsTrackingParameters() {
      const gaCookie = Cookies.get("_ga");
      if (!gaCookie) {
        return;
      }
      // Sample value: GA1.1.284179216.1646168459
      // "GA1.1." is a fixed prefix. Everything after that is the user ID.
      const userId = gaCookie.replace(/^GA1\.1\./, "");
      const userIdNode = document.createElement("input");
      userIdNode.setAttribute("type", "hidden");
      userIdNode.setAttribute("name", "_ga_user_id");
      userIdNode.setAttribute("value", userId);
      form.appendChild(userIdNode);

      // The session_id originates from a different cookie. The cookie contains the container-id from GA4. In this case, the cookie is called _ga_1SEE67N4G0
      // The value of the cookie looks like this: GS1.1.1676450414.18.1.1676452575.46.0.0
      // The session_id value is 1676450414
      const allCookies = Cookies.get();
      const gaSessionCookieKey = Object.keys(allCookies).find((key) =>
        key.match(/^_ga_([a-zA-Z0-9]+)$/),
      );
      if (gaSessionCookieKey) {
        const sessionId = allCookies[gaSessionCookieKey];
        const cleanSessionId = sessionId.replace(/^GS1\.1\./, "").split(".")[0];
        const sessionIdNode = document.createElement("input");
        sessionIdNode.setAttribute("type", "hidden");
        sessionIdNode.setAttribute("name", "_ga_session_id");
        sessionIdNode.setAttribute("value", cleanSessionId);
        form.appendChild(sessionIdNode);
      }

      const cookieConsentLevel = Cookies.get("toc_cookie_consent");
      if (!cookieConsentLevel) {
        return;
      }
      const consentNode = document.createElement("input");
      consentNode.setAttribute("type", "hidden");
      consentNode.setAttribute("name", "_cookie_consent");
      consentNode.setAttribute("value", cookieConsentLevel);
      form.appendChild(consentNode);
    },
  };
};

export const enhancer = (el) => {
  const donateForm = DonateForm(el);
  donateForm.init();
  donateForm.addGoogleAnalyticsTrackingParameters();
};
